<template>
    <b-card>
      <b-card-header>
        <i class="icon-note"></i> Section Voucher Transaction
        <div class="card-header-actions">
          <a class="card-header-action" href="voucher-transaction" rel="noreferrer noopener">
            <small class="text-muted">Back</small>
          </a>
        </div>
      </b-card-header>
      <br>
      <table>
          <tr>
              <td><strong>Transaction Date</strong></td>
              <td>{{ this.created_at}}</td>
          </tr>
          <tr>
              <td><strong>User</strong></td>
              <td>{{ this.user }}</td>
          </tr>
          <tr>
              <td><strong>Transaction Type</strong></td>
              <td>{{ this.transaction_type }}</td>
          </tr>
          <tr>
              <td><strong>Invoice</strong></td>
              <td>{{ this.invoice }}</td>
          </tr>
          <tr>
              <td><strong>Description</strong></td>
              <td>{{ this.description }}</td>
          </tr>
          <tr>
              <td><strong>Gold Amount</strong></td>
              <td><strong>{{ this.amount }}</strong></td>
          </tr>
          <tr>
              <td><strong>IDR Amount</strong></td>
              <td>{{ this.idr_amount }}</td>
          </tr>
          <tr>
              <td><strong>Gold Buy Price</strong></td>
              <td>{{ this.buying_rate }}</td>
          </tr>
          <tr>
              <td><strong>Gold Sell Price</strong></td>
              <td>{{ this.selling_rate }}</td>
          </tr>
          <tr>
              <td><strong>Transaction Status</strong></td>
              <td>{{ this.status }}</td>
          </tr>
      </table>
    </b-card>
</template>

<script>

  export default {
    data() {
      return {
        created_at: '',
        user: '',
        transaction_type: '',
        invoice: '',
        description: '',
        amount: '',
        idr_amount: '',
        buying_rate: '',
        selling_rate: '',
        have_promotion: false,
        status: '',
          name: '',
          module: '',
          item: '',
          type: '',
          after_discount: '',
          startFrom: '',
          endTo: '',
          user_type: '',
          qty: '',
          isAvailable: '',
          organization: '',
          createdAt: '',
          generatedBy: '',
          remaining: '',
          based: '',
          promotion_type: '',
          max_usage: '',
          max_attempt: '',
          errors: {
            code: '',
            message: '',
            status: ''
          },
      }
    },
    created() {
        this.$http.get(`voucher-bucket/` + this.$route.params.id)
        .then((result) => {
          let res = result.data.data;
          this.created_at = res.transaction.created_at
          this.user = res.transaction.user
          this.transaction_type = res.transaction.transaction_type
          this.description = res.transaction.description
          this.invoice = res.transaction.invoice
          this.amount = res.transaction.amount
          this.idr_amount = res.transaction.idr_amount
          this.buying_rate = res.transaction.buying_rate
          this.selling_rate = res.transaction.selling_rate
          this.status = res.transaction.status
        }).catch((error) => {
          if (error.response) {
            this.errors.code = error.response.status;
            this.errors.message = error.response.data.meta.message;
            this.errors.status = error.response.data.meta.code;
          }
        })
    }
  }
</script>

<style>
table {
  border-collapse: collapse;
  width: 100%;
}

td {
  padding: 8px;
  text-align: left;
  border-bottom: 1px solid #ddd;
}
</style>
